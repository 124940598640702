import { Provider } from "react-redux";
import './App.css';

import { store } from './redux/store';
import InnerApp from "./InnerApp";

function App() {
  return (
    <Provider store={store}>
      <InnerApp />
    </Provider>
  );
}

export default App;
