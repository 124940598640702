import React from "react";
import NavItem from "../NavItem/NavItem";
import SeasonSelect from "../SeasonSelect/SeasonSelect";

import styles from "./Header.module.css";

function Header(): JSX.Element {
  return (
    <div className={styles.header}>
      <h1 className={styles.logo}>Canasta Club</h1>
      <nav className={styles.nav}>
        <ul className={styles.navList}>
          <NavItem target="/" label="Punkte" />
          <NavItem target="/games" label="Spiele" />
          <NavItem target="/series" label="Serien" />
        </ul>
      </nav>

      <SeasonSelect />
    </div>
  );
}

export default Header;
