import React from 'react';
import DataLinesDiagram from '../components/content/DataLinesDiagram/DataLinesDiagram';

import { Game } from '../models/Game';
import { Team } from '../models/Team';
import { useAppSelector } from '../redux/hooks';

const pointsUntil = (games: Game[], gameIdx: number, team: Team) => {
  let points = 0;
  for (let i = 0; i <= gameIdx; i++) {
    points += games[i].points[team];
  }
  return points;
}

function Points() {

  const standings = useAppSelector(state => state.standings.standings);

  return (
    <div className="App">
      <h1>Gesamt Punkte</h1>

      <DataLinesDiagram dataFilter={pointsUntil} standings={standings} />
    </div>
  );
}

export default Points;
