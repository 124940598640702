import React from "react";
import { Line } from 'react-chartjs-2';
import { Game } from "../../../models/Game";
import { Team } from "../../../models/Team";

import styles from './DataLinesDiagram.module.css';

type DataLinesDiagramProps = {
  standings: Array<Game>;
  labelFilter: (game: Game) => string;
  dataFilter: (standings: Game[], gameIdx: number, team: Team) => number;
};

/**
 * Card that displays title, text and (optional) action buttons
 *
 */
function DataLinesDiagram(props: DataLinesDiagramProps): JSX.Element {
  const { standings, labelFilter, dataFilter } = props;

  const data = {
    labels: standings.map(labelFilter),
    datasets: [
      {
        label: 'Tade und Fredie',
        data: standings.map((game, gameIdx) => dataFilter(standings, gameIdx, "T&F")),
        fill: false,
        backgroundColor: 'rgb(54, 162, 235)',
        borderColor: 'rgba(54, 162, 235, 0.2)',
      },
      {
        label: 'Johanna und Lina',
        data: standings.map((game, gameIdx) => dataFilter(standings, gameIdx, "J&L")),
        fill: false,
        backgroundColor: 'rgb(255, 99, 132)',
        borderColor: 'rgba(255, 99, 132, 0.2)',
      },
    ],
  };

  return (
    <div className={styles.box}>
      <Line data={data} className={styles.diagram} />
    </div>
  );
}

DataLinesDiagram.defaultProps = {
  labelFilter: (game: Game) => game.date,
};

export default DataLinesDiagram;
