import React from 'react';

import { Game } from '../models/Game';
import { Team } from '../models/Team';
import { useAppSelector } from '../redux/hooks';
import DataLinesDiagram from '../components/content/DataLinesDiagram/DataLinesDiagram';

const gamesWonUntil = (games: Game[], gameIdx: number, team: Team) => {
  let gamesWon = 0;
  for (let i = 0; i <= gameIdx; i++) {
    if (games[i].gameWinner === team) {
      gamesWon++;
    }
  }
  return gamesWon;
}

function Games() {
  const standings = useAppSelector(state => state.standings.standings);

  return (
    <div className="App">
      <h1>Gesamt Spiele</h1>

      <DataLinesDiagram dataFilter={gamesWonUntil} standings={standings} />
    </div>
  );
}

export default Games;
