import React from "react";
import { NavLink } from "react-router-dom";

import styles from "./NavItem.module.css";

interface NavItemProps {
  target: string;
  label: string;
};

function NavItem(props: NavItemProps): JSX.Element {
  const { target, label } = props;
  return (
    <li className={styles.item}>
      <NavLink exact to={target} className={styles.link} activeStyle={{textDecoration: "underline"}}>{label}</NavLink>
    </li>
  );
}

export default NavItem;
