import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Game } from "../../models/Game";

// Define a type for the slice state
interface StandingsState {
  standings: Array<Game>;
}

// Define the initial state using that type
const initialState: StandingsState = {
  standings: [],
};

export const standingsSlice = createSlice({
  name: "standings",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setStandings: (state, action: PayloadAction<Game[]>) => {
      return {
        ...state,
        standings: action.payload,
      };
    },
  },
});

export const { setStandings } = standingsSlice.actions;

export default standingsSlice.reducer;
