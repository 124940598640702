import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { useEffect } from "react";
import './App.css';

import Games from './routes/Games';
import Points from './routes/Points';
import Series from './routes/Series';
import { useAppDispatch } from "./redux/hooks";
import { setStandings } from "./redux/reducer/standings";
import StandingsService from "./services/StandingsService";
import Header from "./components/global/Header/Header";
import SeasonService from "./services/SeasonService";



function InnerApp() {

  const dispatch = useAppDispatch();

  useEffect(() => {
    StandingsService.getStandings(SeasonService.currentSeason).then(standings => {
      dispatch(setStandings(standings));
    });

  }, [dispatch]);
  
  return (
      <Router>
        <div>
          <Header />
          <Switch>
            <Route path="/series">
              <Series />
            </Route>
            <Route path="/games">
              <Games />
            </Route>
            <Route path="/" component={Points} />
          </Switch>
        </div>
      </Router>
  );
}

export default InnerApp;
