import StorageService from "./StorageService";

class SeasonService {
  private storageKey = "cs_season"
  private defaultSeason = "2021/2022"

  get currentSeason() {
    return StorageService.getItem(this.storageKey) || this.defaultSeason;
  }

  set currentSeason(season: string) {
    if (season === this.currentSeason) return;

    StorageService.addItem(this.storageKey, season);
  }

}

export default new SeasonService();
