import React from 'react';

import { Game } from '../models/Game';
import { Team } from '../models/Team';
import { useAppSelector } from '../redux/hooks';
import DataLinesDiagram from '../components/content/DataLinesDiagram/DataLinesDiagram';

const seriesWonUntil = (games: Game[], gameIdx: number, team: Team) => {
  let seriesWon = 0;
  for (let i = 0; i <= gameIdx; i++) {
    if (games[i].gameWinner === team) {
      seriesWon++;
    }
  }
  return seriesWon;
}

function Series() {
  const games = useAppSelector(state => state.standings.standings);

  const standings = games.filter(game => game.isSeriesWinner);

  return (
    <div className="App">
      <h1>Gesamt Serien</h1>

      <DataLinesDiagram dataFilter={seriesWonUntil} standings={standings} />
    </div>
  );
}

export default Series;
