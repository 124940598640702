import { Game } from "../models/Game";
import APIService from "./APIService";

class StandingsService {

  get endpointUrl() {
    return process.env.REACT_APP_API_ENDPOINT || "http://localhost:3005/";
  }

  async getStandings(season: string): Promise<Game[]> {
    const standings = await APIService.fetchJSON<Game[]>(this.endpointUrl, {
      fetchOptions: {
        method: "POST",
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({season})
      }
    });

    return standings || [];
  }
}

export default new StandingsService();
