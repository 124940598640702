import React from "react";
import { useAppDispatch } from "../../../redux/hooks";
import { setStandings } from "../../../redux/reducer/standings";
import SeasonService from "../../../services/SeasonService";
import StandingsService from "../../../services/StandingsService";

import styles from "./SeasonSelect.module.css";

function SeasonSelect(): JSX.Element {

  const dispatch = useAppDispatch();

  const season = SeasonService.currentSeason;

  const handleSeasonChange = async (event: React.ChangeEvent<HTMLSelectElement>) => {
    SeasonService.currentSeason = event.target.value;
    const standings = await StandingsService.getStandings(SeasonService.currentSeason)
    
    dispatch(setStandings(standings));
  }

  return (
    <select className={styles.select} defaultValue={season} onChange={handleSeasonChange}>
      <option value="2020/2021">2020/2021</option>
      <option value="2021/2022">2021/2022</option>
    </select>
  );
}

export default SeasonSelect;
